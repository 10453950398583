import Platform from '@custom-types/Platform';
import Product from '@custom-types/Product';
import { AppLauncher } from '~capacitor-plugins/app-launcher';
import { Capacitor } from '~capacitor-plugins/core';

export enum StoreLinks {
  CheckInIos = 'https://apps.apple.com/app/eventmobi-onsite/id1617146323',
  CheckInAndroid = 'https://play.google.com/store/apps/details?id=com.eventmobi.onsite',
  LeadCaptureIos = 'https://apps.apple.com/app/lead-capture-by-eventmobi/id6461824837',
  LeadCaptureAndroid = 'https://play.google.com/store/apps/details?id=com.eventmobi.leadcapture',
}

const links = {
  [Platform.Ios]: {
    [Product.CheckIn]: StoreLinks.CheckInIos,
    [Product.LeadCapture]: StoreLinks.LeadCaptureIos,
  },
  [Platform.Android]: {
    [Product.CheckIn]: StoreLinks.CheckInAndroid,
    [Product.LeadCapture]: StoreLinks.LeadCaptureAndroid,
  },
  [Platform.Web]: {
    [Product.CheckIn]: StoreLinks.CheckInAndroid,
    [Product.LeadCapture]: StoreLinks.LeadCaptureAndroid,
  },
};

export const openLink = (type: Product) => {
  const platform = Capacitor.getPlatform() as Platform;

  if (type !== Product.Legacy) {
    const url = links[platform][type];

    AppLauncher.openUrl({ url });
  }
};
