import { DevicePlugin } from '@capacitor/device/dist/esm/definitions';

export * from '@capacitor/device/dist/esm/definitions';
// We are intentionally using a mutable export so that we can mimic the original import
// syntax for Capacitor plugins, while still dynamically loading the correct capacitor version.
// Don't remove or try to fix the skipcq, as this syntax is mandatory for the compatibility bridge.
// skipcq: JS-E1009
export let Device: DevicePlugin;

const load = ({ Device: device }: { Device: DevicePlugin }) => {
  Device = device;
};

export default load;
