import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Locale } from '@services/language-service';

// eslint-disable-next-line import/no-cycle
import { RootState } from './store';
import { setUser } from './user';

export interface State {
  isLoggedIn: boolean;
  isOnline: boolean;
  locale: Locale;
}

export const initialState: State = {
  isLoggedIn: false,
  isOnline: true,
  locale: Locale.En,
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    loggedIn: (state: State) => {
      state.isLoggedIn = true;
    },
    loggedOut: (state: State) => {
      state.isLoggedIn = false;
    },
    setIsOnline: (state: State, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    setLocale: (state: State, action: PayloadAction<Locale>) => {
      state.locale = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(setUser, (state, { payload }) => {
      state.isLoggedIn = !!payload?.email;
    });
  },
});

export default systemSlice;

export const getIsLoggedIn = (state: RootState) => state[systemSlice.name].isLoggedIn;
export const getIsOnline = (state: RootState) => state[systemSlice.name].isOnline;
export const getLocale = (state: RootState) => state[systemSlice.name].locale;

export const {
  actions: { loggedIn, loggedOut, setIsOnline, setLocale },
  reducer: systemReducer,
} = systemSlice;
