import { IonSpinner, setupIonicReact } from '@ionic/react';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import useRegisterCapacitorPlugins from '~capacitor-plugins/index';

import { Provider as UpdateRequiredProvider } from './pages/UpdateRequired';
import { unregister } from './serviceWorkerRegistration';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

import './setup-sentry';

setupIonicReact();

const App = lazy(() => import(/* webpackChunkName: "app" */ './App'));

const Spinner = styled(IonSpinner)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Bootstrap = () => {
  const { isRegistering, isObsoleteVersion } = useRegisterCapacitorPlugins();

  return (
    <Suspense fallback={<Spinner name="circular" color="primary" />}>
      <UpdateRequiredProvider isObsoleteVersion={isObsoleteVersion}>
        {isRegistering && <Spinner name="circular" color="primary" />}
        {!isRegistering && <App />}
      </UpdateRequiredProvider>
    </Suspense>
  );
};

ReactDOM.render(<Bootstrap />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
unregister();
