import { ExtractRouteParams, generatePath } from 'react-router';

import Product from '@custom-types/Product';

export const getProduct = () => {
  const { pathname } = window.location;
  const regexp = new RegExp(`/(${Product.CheckIn}|${Product.LeadCapture})`);
  const [, product] = (pathname?.match(regexp) as [string, Product]) ?? [undefined, Product.Legacy];
  return product;
};

export const generateRoute = <T extends string | string[]>(
  routePath?: T,
  params?: ExtractRouteParams<string>
): T => {
  if (Array.isArray(routePath)) {
    return routePath.map(value => generateRoute(value)) as T;
  }

  const product = getProduct();
  if (!routePath) {
    return `/${product}` as T;
  }

  const path = `/${product}${routePath}`;

  if (params) {
    return generatePath(path, params) as T;
  }

  return path as T;
};
