import type {
  CapacitorGlobal,
  PluginListenerHandle as IPluginListenerHandle,
} from '@capacitor/core';

export type PluginListenerHandle = IPluginListenerHandle;
// We are intentionally using a mutable export so that we can mimic the original import
// syntax for Capacitor plugins, while still dynamically loading the correct capacitor version.
// Don't remove or try to fix the skipcq, as this syntax is mandatory for the compatibility bridge.
// skipcq: JS-E1009
export let Capacitor: CapacitorGlobal;

const load = ({ Capacitor: capacitor }: { Capacitor: CapacitorGlobal }) => {
  Capacitor = capacitor;
};

export default load;
