import { SplashScreenPlugin } from '@capacitor/splash-screen/dist/esm/definitions';

export * from '@capacitor/splash-screen/dist/esm/definitions';
// We are intentionally using a mutable export so that we can mimic the original import
// syntax for Capacitor plugins, while still dynamically loading the correct capacitor version.
// Don't remove or try to fix the skipcq, as this syntax is mandatory for the compatibility bridge.
// skipcq: JS-E1009
export let SplashScreen: SplashScreenPlugin;

const load = ({ SplashScreen: splashScreen }: { SplashScreen: SplashScreenPlugin }) => {
  SplashScreen = splashScreen;
};

export default load;
