import { IonButton, IonText } from '@ionic/react';
import React, { useContext, createContext } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';

import { ReactComponent as Image } from '@assets/update-app.svg';
import Layout from '@pages/Auth/Layout';
import { getProduct } from '@services/navigation-service';
import { openLink } from '@services/store-link-service';

const Context = createContext<boolean>(false);

export const Provider = ({
  isObsoleteVersion,
  children,
}: {
  isObsoleteVersion: boolean;
  children: React.ReactNode;
}) => <Context.Provider value={isObsoleteVersion}>{children}</Context.Provider>;

export const useIsObsoleteVersion = () => useContext(Context);

const Button = styled(IonButton)`
  width: 100%;
  padding: 16px 5px 0px;
`;

const Text = styled(IonText)`
  padding: 0 5px;
`;

export const UpdateRequired = () => (
  <Layout hideFooter hideLogo>
    <>
      <Text>
        <h2>{t`App Update Required`} </h2>
      </Text>
      <Image />
      <Text>
        <p>{t`The current version of the app you are using is outdated.`}</p>
        <p>{t`Please update the app to the latest version by clicking the Update App button below`}</p>
      </Text>
      <Button
        expand="block"
        // skipcq: JS-0417
        onClick={() => openLink(getProduct())}
      >
        {t`Update App`}
      </Button>
    </>
  </Layout>
);
