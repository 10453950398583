import * as Sentry from '@sentry/react';
import type { Event, Response, Transport } from '@sentry/types';

declare const SENTRY_DSN: string;
declare const PRODUCTION: string;
declare const DEVELOPMENT: string;
declare const PULL_REQUEST_PREVIEW: string;
declare const RELEASE: string;
declare const ENV: string;

interface SentryTransportMemory {
  transactions: Event[];
  reports: Event[];
}

declare global {
  interface Window {
    Sentry: SentryTransportMemory;
  }
}

class MemoryTransport implements Transport {
  static Memory: SentryTransportMemory;

  private readonly transactions: Event[];

  private readonly reports: Event[];

  constructor() {
    if (!MemoryTransport.Memory) {
      throw new Error('MemoryTransport.Memory must be set before using MemoryTransport');
    }

    this.transactions = MemoryTransport.Memory.transactions;
    this.reports = MemoryTransport.Memory.reports;
  }

  sendEvent(event: Event): PromiseLike<Response> {
    if (event.type === 'transaction') {
      this.transactions.push(event);
    } else {
      this.reports.push(event);
    }

    return Promise.resolve({
      status: 'success',
      event,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  close(): PromiseLike<boolean> {
    return Promise.resolve(true);
  }
}

const config: Sentry.BrowserOptions = {
  enabled: !PULL_REQUEST_PREVIEW,
  dsn: SENTRY_DSN,
  release: RELEASE,
  environment: ENV,
  sampleRate: PRODUCTION ? 0.25 : 1,
  ignoreErrors: [
    // Network errors which we can ignore
    /Request failed with status code/,
    /Failed to fetch/,
    /NetworkError/,
    /Non-Error promise rejection captured/,
  ],
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (hint && breadcrumb.data && breadcrumb.category === 'xhr') {
      try {
        const response = JSON.parse(hint.xhr.response);
        const requestId = response.meta?.request_id ?? response.meta?.request?.request_id;

        if (requestId) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data.requestId = requestId;
        }

        if (breadcrumb.data.status_code >= 400) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data.errors = response.errors;
        }
      } catch {
        // do nothing
      }
    }

    return breadcrumb;
  },
};

// Dev-only logging so we can verify what our handlers are doing without actually
// sending anything to Sentry
if (DEVELOPMENT || PULL_REQUEST_PREVIEW) {
  // Must match their DSN validation:
  // https://github.com/getsentry/sentry-javascript/blob/bfe819d6737dafc86c60419f644c55a4630bb626/packages/utils/src/dsn.ts#L35
  config.dsn = 'https://dummy@dummy/123456';

  // Use the MemoryTransport instead of the default FetchTransport
  MemoryTransport.Memory = {
    transactions: [],
    reports: [],
  };
  config.transport = MemoryTransport;

  // export it to the window so we can access it
  window.Sentry = MemoryTransport.Memory;
}

Sentry.init(config);
