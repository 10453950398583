import { useCallback, useEffect, useState } from 'react';

export enum Theme {
  Light,
  Dark,
}
// detects if device is using light or dark mode, and listens for that change
export default function useDeviceTheme() {
  const [theme, setTheme] = useState<Theme>();

  const listener = useCallback((event: MediaQueryListEvent) => {
    const deviceTheme = event.matches ? Theme.Dark : Theme.Light;
    setTheme(deviceTheme);
  }, []);

  useEffect(() => {
    // set initial theme
    const deviceTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? Theme.Dark
      : Theme.Light;
    setTheme(deviceTheme);
    // listen for theme changes
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', listener);

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', listener);
    };
  }, [listener]);

  return theme;
}
