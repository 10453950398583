import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserRole, UserSession } from '@custom-types/UserSession';

// eslint-disable-next-line import/no-cycle
import { RootState } from './store';
import { loggedOut } from './system';

interface State {
  id: string;
  role: UserRole | null;
  name: string;
  peopleId: string;
  email: string;
}

export const initialState: State = {
  id: '',
  role: null,
  name: '',
  peopleId: '',
  email: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPeopleId: (state: State, action: PayloadAction<string>) => {
      state.peopleId = action.payload;
    },
    setUser: (state: State, action: PayloadAction<UserSession>) => {
      state.id = action.payload.userId;
      state.role = action.payload.userRole;
      state.name = action.payload.firstName;
      state.peopleId = (action.payload.peopleId || state.peopleId) ?? '';
      state.email = action.payload.email ?? '';
    },
    removeUser: (state: State) => {
      state.id = '';
      state.role = null;
      state.name = '';
      state.peopleId = '';
      state.email = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(loggedOut, state => {
      state.id = '';
      state.role = null;
      state.name = '';
      state.peopleId = '';
      state.email = '';
    });
  },
});

export default userSlice;

export const getId = (state: RootState) => state[userSlice.name].id;
export const getRole = (state: RootState) => state[userSlice.name].role;
export const getName = (state: RootState) => state[userSlice.name].name;
export const getPeopleId = (state: RootState) => state[userSlice.name].peopleId;
export const getEmail = (state: RootState) => state[userSlice.name].email;

export const {
  actions: { setPeopleId, setUser, removeUser },
  reducer: userReducer,
} = userSlice;
