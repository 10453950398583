import axios from 'axios';
import { addLocale, useLocale as updateLocale } from 'ttag';

import { Device } from '~capacitor-plugins/device';

export enum Locale {
  De = 'de',
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  Pt = 'pt',
}
export const DEFAULT_LOCALE = Locale.En;
const LOCALE_TO_TRANSIFEX = {
  [Locale.De]: 'de_DE',
  [Locale.En]: 'en',
  [Locale.Es]: 'es_ES',
  [Locale.Fr]: 'fr_CA',
  [Locale.Pt]: 'pt_BR',
};

export async function getDeviceLocale() {
  try {
    const { value } = await Device.getLanguageCode();
    return Object.values(Locale).includes(value as Locale) ? (value as Locale) : Locale.En;
  } catch (err) {
    throw Error('Failed to get device language');
  }
}

export function setLocale(locale: Locale) {
  if (locale === Locale.En) {
    updateLocale(locale);
    return Promise.resolve();
  }
  return axios.get(`/translations/${LOCALE_TO_TRANSIFEX[locale]}.json`).then(response => {
    addLocale(locale, response.data);
    updateLocale(locale);
  });
}

export async function initLocale() {
  const res = await getDeviceLocale();
  await setLocale(res);
}
