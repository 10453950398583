import { BrowserPlugin } from '@capacitor/browser/dist/esm/definitions';

export * from '@capacitor/browser/dist/esm/definitions';
// We are intentionally using a mutable export so that we can mimic the original import
// syntax for Capacitor plugins, while still dynamically loading the correct capacitor version.
// Don't remove or try to fix the skipcq, as this syntax is mandatory for the compatibility bridge.
// skipcq: JS-E1009
export let Browser: BrowserPlugin;

const load = ({ Browser: browser }: { Browser: BrowserPlugin }) => {
  Browser = browser;
};

export default load;
